import Flickity from 'Flickity'

function DOMready(callback) {
  if (document.readyState != 'loading') callback()
  else document.addEventListener('DOMContentLoaded', callback)
}

DOMready(function () {
  const optionsFlkty = {
    cellAlign: 'left',
    prevNextButtons: false,
    pageDots: true,
    contain: true,
    watchCSS: true
    // adaptiveHeight: false
    // dragThreshold: 1,
    // selectedAttraction: 0.2,
    // friction: 0.9,
  }

  const carouselArray = document.querySelectorAll('.easy-steps__content')

  if (carouselArray.length > 0) {
    carouselArray.forEach((carousel) => {
      // eslint-disable-next-line no-unused-vars
      new Flickity(carousel, optionsFlkty).resize()

    })
  }
})
